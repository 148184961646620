<script lang="ts" setup>
  import type { PloneTileCards } from '~/types/Plone'

  const props = defineProps<{
    data: PloneTileCards
  }>()

  const columns = parseInt(props.data.layout_columns)
</script>

<template>
  <app-section
    class="tile-listing"
    :title="data?.title"
    :description="data?.description"
    header-container-width="text"
    :read-more-link="data?.more_link?.['@id']"
    :read-more-text="data?.more_link_text || data?.more_link?.title">
    <app-container
      v-if="data.items"
      :narrow="columns <= 3 || !columns">
      <card-group
        :columns="columns"
        :match-height="columns > 1 && '.app-card__header'"
        :show-description="data.show_descriptions"
        :show-image="data.show_images"
        :items="data.items"/>
    </app-container>
  </app-section>
</template>
